import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { FC, useEffect } from 'react';

import { exportDynamic } from '@/utils/export-dynamic';

export const SignIn: FC = () => {
    const router = useRouter();
    const { status } = useSession();
    const { data: session } = useSession();
    const isCampaignsAdmin = !!session?.user?.roles?.isCampaignsAdmin;
    const isMyBotsAdmin = !!session?.user?.roles?.isMyBotsAdmin;
    const isAnalyticsAdmin = !!session?.user?.roles?.isAnalyticsAdmin;

    useEffect(() => {
        if (status === 'unauthenticated') {
            signIn('keycloak');
        } else if (status === 'authenticated') {
            if (isCampaignsAdmin) {
                router.push('/campaigns');
            } else if (isMyBotsAdmin) {
                router.push('/bots');
            } else if (isAnalyticsAdmin) {
                router.push('/analytics');
            } else {
                router.push('/');
            }
        }
    }, [router, status, isCampaignsAdmin, isMyBotsAdmin, isAnalyticsAdmin]);
    return <></>;
};

export default exportDynamic(SignIn);
